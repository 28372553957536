<template>
  <div class="article_background">
    <div class="article_pannel">
      <el-row type="flex" :gutter="5" class="article_info_pannel"
        ><el-col
          class="article_info_box article_info_box_left"
          :span="4"
        >
          <el-menu :default-active="activeType">
            <el-menu-item
              v-for="(item, index) in infoList"
              :key="index"
              :index="item.un_code"
              @click="changeArticleType(item.un_code)"
            >
              <template slot="title">
                <i class="el-icon-document"></i>
                <span>{{ item.type_name }}</span>
              </template>
            </el-menu-item>
          </el-menu> </el-col
        ><el-col :span="isLogin ? 20 : 24"
          ><div class="article_info_box article_info_box_center">
            <ArticleSelect ref="ArticleSelect" /></div></el-col
      ></el-row>
    </div>
  </div>
</template>
  
  <script>
import ArticleSelect from "./ArticleSelect/index.vue";
export default {
  components: { ArticleSelect },
  data() {
    return {
      //文章类型列表
      infoList: [],
      //当前选中的文章类型业务主键
      articleTypeUnCode:"",
      activeType:""
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    //获取用户信息判断用户是否登录
    getInfo() {
      this.$store.dispatch("articleType/select").then((res) => {
        var { data } = res;
        this.infoList = data.info_list;
        //如果文章类型列表不为空，则初始化当前选中的文章类型
        if (this.infoList.length > 0) {
          this.activeType = this.infoList[0].un_code;
          this.articleTypeUnCode = this.activeType
          this.$refs.ArticleSelect.getArticleInfo(this.articleTypeUnCode)
        }
      });
    },
    changeArticleType(data){
      this.articleTypeUnCode = data
      this.$refs.ArticleSelect.getArticleInfo(this.articleTypeUnCode)
    }
  },
};
</script>
  
  <style scoped>
.article_background {
  overflow-x: hidden;
}
.article_pannel {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.69);
}
.article_info_pannel {
  padding: 10vh 10vh 0vh 10vh;
}
.el-card {
  border: 0px;
}
.el-menu {
  border: 0px;
  background-color: rgba(0, 0, 0, 0);
}
.el-menu-item {
  margin: 1rem 0rem;
  color: #fff;
}

.el-menu-item.is-active {
  color: #ffd04b;
}

.article_info_box_left .el-menu {
  margin-top: 1vh;
  margin-right: 1vh;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
}
.article_info_box_center {
  min-height: 80vh;
  width: 100%;
  background-color: rgba(255, 255, 255, 0);
}
</style>